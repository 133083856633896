// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Karmapoints from "../../blocks/karmapoints/src/Karmapoints";
import Mentionstagging from "../../blocks/mentionstagging/src/Mentionstagging";
import Customisableuserprofiles2 from "../../blocks/customisableuserprofiles2/src/Customisableuserprofiles2";
import Timetracker2 from "../../blocks/timetracker2/src/Timetracker2";
import Matchalgorithm2 from "../../blocks/matchalgorithm2/src/Matchalgorithm2";
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import Likeapost2 from "../../blocks/likeapost2/src/Likeapost2";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import Surveys2 from "../../blocks/surveys2/src/Surveys2";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import Elasticsearch2 from "../../blocks/elasticsearch2/src/Elasticsearch2";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Baselinereporting from "../../blocks/baselinereporting/src/Baselinereporting";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import Customform3 from "../../blocks/customform3/src/Customform3";
import Cfportfoliomanagerdashboard4 from "../../blocks/cfportfoliomanagerdashboard4/src/Cfportfoliomanagerdashboard4";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Contentmanagement3 from "../../blocks/contentmanagement3/src/Contentmanagement3";
import Cfparameterconfigurablefile3 from "../../blocks/cfparameterconfigurablefile3/src/Cfparameterconfigurablefile3";
import Assessmenttest from "../../blocks/assessmenttest/src/Assessmenttest";
import Cfcustomrolecreation6 from "../../blocks/cfcustomrolecreation6/src/Cfcustomrolecreation6";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Recommendationengine from "../../blocks/recommendationengine/src/Recommendationengine";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import MultipleCurrencySupport from "../../blocks/multiplecurrencysupport/src/MultipleCurrencySupport";
import Notifications from "../../blocks/notifications/src/Notifications";
import Promotecontent from "../../blocks/promotecontent/src/Promotecontent";
import Download from "../../blocks/download/src/Download";
import Audittrail2 from "../../blocks/audittrail2/src/Audittrail2";
import Questionbank from "../../blocks/questionbank/src/Questionbank";
import Cfattachmentsizeandcharacterlimit2 from "../../blocks/cfattachmentsizeandcharacterlimit2/src/Cfattachmentsizeandcharacterlimit2";
import Projectportfolio from "../../blocks/projectportfolio/src/Projectportfolio";
import Formapprovalworkflow from "../../blocks/formapprovalworkflow/src/Formapprovalworkflow";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import Blogpostsmanagement from "../../blocks/blogpostsmanagement/src/Blogpostsmanagement";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Subscriptionbilling2 from "../../blocks/subscriptionbilling2/src/Subscriptionbilling2";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Addfriends from "../../blocks/addfriends/src/Addfriends";
import Cfcarboncalculator from "../../blocks/cfcarboncalculator/src/Cfcarboncalculator";
import Communityforum3 from "../../blocks/communityforum3/src/Communityforum3";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Portfoliomanagement from "../../blocks/portfoliomanagement/src/Portfoliomanagement";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Share from "../../blocks/share/src/Share";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import ActivityFeed from "../../blocks/activityfeed/src/ActivityFeed";
import Favourites from "../../blocks/favourites/src/Favourites";
import AddFavourites from "../../blocks/favourites/src/AddFavourites";
import Followers from "../../blocks/followers/src/Followers";
import Projectreporting from "../../blocks/projectreporting/src/Projectreporting";
import Cfriskmanagementlibrary from "../../blocks/cfriskmanagementlibrary/src/Cfriskmanagementlibrary";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement";
import Cfsurveyreporting from "../../blocks/cfsurveyreporting/src/Cfsurveyreporting";
import Conversationthreading2 from "../../blocks/conversationthreading2/src/Conversationthreading2";
import Videolibrary2 from "../../blocks/videolibrary2/src/Videolibrary2";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import Cfrecommendedprojectmanagement from "../../blocks/cfrecommendedprojectmanagement/src/Cfrecommendedprojectmanagement";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import Scoring from "../../blocks/scoring/src/Scoring";
import Videos from "../../blocks/videos/src/Videos";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import Chat from "../../blocks/chat/src/Chat";
import ChatView from "../../blocks/chat/src/ChatView";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import Cfproductlisting11 from "../../blocks/cfproductlisting11/src/Cfproductlisting11";
import Cfcourselisting5 from "../../blocks/cfcourselisting5/src/Cfcourselisting5";
import Contentmoderation from "../../blocks/contentmoderation/src/Contentmoderation";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import Joblisting from "../../blocks/joblisting/src/Joblisting";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Projectnotes from "../../blocks/projectnotes/src/Projectnotes";
import Analytics from "../../blocks/analytics/src/Analytics";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Cffundingpage2 from "../../blocks/cffundingpage2/src/Cffundingpage2";
import Microsoftlogin3 from "../../blocks/microsoftlogin3/src/Microsoftlogin3";
import Maps from "../../blocks/maps/src/Maps";
import ReviewApprovalAdmin from "../../blocks/reviewandapproval/src/ReviewApprovalAdmin";
import ReviewApprovalBasicUser from "../../blocks/reviewandapproval/src/ReviewApprovalBasicUser";



const routeMap = {
Karmapoints:{
 component:Karmapoints,
path:"/Karmapoints"},
Mentionstagging:{
 component:Mentionstagging,
path:"/Mentionstagging"},
Customisableuserprofiles2:{
 component:Customisableuserprofiles2,
path:"/Customisableuserprofiles2"},
Timetracker2:{
 component:Timetracker2,
path:"/Timetracker2"},
Matchalgorithm2:{
 component:Matchalgorithm2,
path:"/Matchalgorithm2"},
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
Likeapost2:{
 component:Likeapost2,
path:"/Likeapost2"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
Surveys2:{
 component:Surveys2,
path:"/Surveys2"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
Elasticsearch2:{
 component:Elasticsearch2,
path:"/Elasticsearch2"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Baselinereporting:{
 component:Baselinereporting,
path:"/Baselinereporting"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
Customform3:{
 component:Customform3,
path:"/Customform3"},
Cfportfoliomanagerdashboard4:{
 component:Cfportfoliomanagerdashboard4,
path:"/Cfportfoliomanagerdashboard4"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Contentmanagement3:{
 component:Contentmanagement3,
path:"/Contentmanagement3"},
Cfparameterconfigurablefile3:{
 component:Cfparameterconfigurablefile3,
path:"/Cfparameterconfigurablefile3"},
Assessmenttest:{
 component:Assessmenttest,
path:"/Assessmenttest"},
Cfcustomrolecreation6:{
 component:Cfcustomrolecreation6,
path:"/Cfcustomrolecreation6"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Recommendationengine:{
 component:Recommendationengine,
path:"/Recommendationengine"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
MultipleCurrencySupport:{
 component:MultipleCurrencySupport,
path:"/MultipleCurrencySupport"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Promotecontent:{
 component:Promotecontent,
path:"/Promotecontent"},
Download:{
 component:Download,
path:"/Download"},
Audittrail2:{
 component:Audittrail2,
path:"/Audittrail2"},
Questionbank:{
 component:Questionbank,
path:"/Questionbank"},
Cfattachmentsizeandcharacterlimit2:{
 component:Cfattachmentsizeandcharacterlimit2,
path:"/Cfattachmentsizeandcharacterlimit2"},
Projectportfolio:{
 component:Projectportfolio,
path:"/Projectportfolio"},
Formapprovalworkflow:{
 component:Formapprovalworkflow,
path:"/Formapprovalworkflow"},
Paymentadmin2:{
 component:Paymentadmin2,
path:"/Paymentadmin2"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
Blogpostsmanagement:{
 component:Blogpostsmanagement,
path:"/Blogpostsmanagement"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Subscriptionbilling2:{
 component:Subscriptionbilling2,
path:"/Subscriptionbilling2"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
Addfriends:{
 component:Addfriends,
path:"/Addfriends"},
Cfcarboncalculator:{
 component:Cfcarboncalculator,
path:"/Cfcarboncalculator"},
Communityforum3:{
 component:Communityforum3,
path:"/Communityforum3"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Portfoliomanagement:{
 component:Portfoliomanagement,
path:"/Portfoliomanagement"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Share:{
 component:Share,
path:"/Share"},
Rolesandpermissions:{
 component:Rolesandpermissions,
path:"/Rolesandpermissions"},
ActivityFeed:{
 component:ActivityFeed,
path:"/ActivityFeed"},
Favourites:{
 component:Favourites,
path:"/Favourites"},
AddFavourites:{
 component:AddFavourites,
path:"/AddFavourites"},
Followers:{
 component:Followers,
path:"/Followers"},
Projectreporting:{
 component:Projectreporting,
path:"/Projectreporting"},
Cfriskmanagementlibrary:{
 component:Cfriskmanagementlibrary,
path:"/Cfriskmanagementlibrary"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
Cfsurveyreporting:{
 component:Cfsurveyreporting,
path:"/Cfsurveyreporting"},
Conversationthreading2:{
 component:Conversationthreading2,
path:"/Conversationthreading2"},
Videolibrary2:{
 component:Videolibrary2,
path:"/Videolibrary2"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
Cfrecommendedprojectmanagement:{
 component:Cfrecommendedprojectmanagement,
path:"/Cfrecommendedprojectmanagement"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
Scoring:{
 component:Scoring,
path:"/Scoring"},
Videos:{
 component:Videos,
path:"/Videos"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
Chat:{
 component:Chat,
path:"/Chat"},
ChatView:{
 component:ChatView,
path:"/ChatView"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
Cfproductlisting11:{
 component:Cfproductlisting11,
path:"/Cfproductlisting11"},
Cfcourselisting5:{
 component:Cfcourselisting5,
path:"/Cfcourselisting5"},
Contentmoderation:{
 component:Contentmoderation,
path:"/Contentmoderation"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
Joblisting:{
 component:Joblisting,
path:"/Joblisting"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Projectnotes:{
 component:Projectnotes,
path:"/Projectnotes"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
PhotoLibrary:{
 component:PhotoLibrary,
path:"/PhotoLibrary"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Cffundingpage2:{
 component:Cffundingpage2,
path:"/Cffundingpage2"},
Microsoftlogin3:{
 component:Microsoftlogin3,
path:"/Microsoftlogin3"},
Maps:{
 component:Maps,
path:"/Maps"},
ReviewApprovalAdmin:{
 component:ReviewApprovalAdmin,
path:"/ReviewApprovalAdmin"},
ReviewApprovalBasicUser:{
 component:ReviewApprovalBasicUser,
path:"/ReviewApprovalBasicUser"},

  Home: {
component:OTPInputAuth,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
